// src/components/Header.js
import React from 'react';

const Header = ({ isDarkMode, toggleTheme }) => {
    return (
        <div className="header">
            <div className="theme-switcher">
                <button className="theme-switcher-icon">
                    {isDarkMode ? '🌙' : '☀️'}
                </button>
                <div className="dropdown-content">
                    <button
                        className={isDarkMode ? 'inactive' : 'active'}
                        onClick={() => { if (isDarkMode) toggleTheme(); }}
                    >
                        Light
                    </button>
                    <button
                        className={isDarkMode ? 'active' : 'inactive'}
                        onClick={() => { if (!isDarkMode) toggleTheme(); }}
                    >
                        Dark
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Header;
