// src/components/Body.js
import React from 'react';

const Body = ({
    account,
    connectMetaMask,
    contractAddress,
    setContractAddress,
    loadContract,
    contract,
    error,
    functionInputs,
    handleFunctionCall,
    handleFunctionSend,
    handleInputChange,
    contractInfo
}) => {
    const renderFunctionInputs = (funcName, inputs) => {
        return inputs.map((input, index) => (
            <div key={index} className="input-container">
                <label>{input.name} ({input.type}):</label>
                <input type="text" onChange={(e) => handleInputChange(funcName, index, e.target.value)} />
            </div>
        ));
    };

    const renderFunctions = () => {
        if (!contract) return null;

        const readFunctions = contract.options.jsonInterface.filter(item => item.type === 'function' && item.stateMutability === 'view');
        const writeFunctions = contract.options.jsonInterface.filter(item => item.type === 'function' && item.stateMutability !== 'view');

        return (
            <div className="functions-wrapper">
                <div className="read-functions">
                    <h2>Read Functions</h2>
                    {readFunctions.map((func, index) => (
                        <div key={index} className="function-container">
                            <h3>{func.name}</h3>
                            {renderFunctionInputs(func.name, func.inputs)}
                            <button className="call-button" onClick={() => handleFunctionCall(func.name)}>Call {func.name}</button>
                            {contractInfo[func.name] && <p>Result: {JSON.stringify(contractInfo[func.name])}</p>}
                        </div>
                    ))}
                </div>
                <div className="write-functions">
                    <h2>Write Functions</h2>
                    {writeFunctions.map((func, index) => (
                        <div key={index} className="function-container">
                            <h3>{func.name}</h3>
                            {renderFunctionInputs(func.name, func.inputs)}
                            <button className="send-button" onClick={() => handleFunctionSend(func.name)}>Send {func.name}</button>
                            {contractInfo[func.name] && <p>Transaction: {JSON.stringify(contractInfo[func.name])}</p>}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="main-content">
            <div className="centered-container">
                <h1>Harmony Token DApp</h1>
                {!account && <button className="connect-button" onClick={connectMetaMask}>Connect MetaMask</button>}
                {account && <p>Connected account: {account}</p>}
                <div className="input-container">
                    <label>Contract Address:</label>
                    <input
                        type="text"
                        value={contractAddress}
                        onChange={(e) => setContractAddress(e.target.value)}
                    />
                </div>
                <button className="load-button" onClick={loadContract}>Load Contract</button>

                {error && <p className="error">{error}</p>}

                <div>
                    <p>To find the ABI:</p>
                    <ol>
                        <li>Go to the Harmony Explorer: <a href={`https://explorer.harmony.one/address/${contractAddress}?tab=contract&shard=0`} target="_blank" rel="noopener noreferrer">Harmony Explorer Contract Page</a></li>
                        <li>Navigate to the "Contract" tab.</li>
                        <li>Copy the ABI and paste it in the "Contract ABI" field above.</li>
                    </ol>
                </div>
                {contract && (
                    <div className="functions-container">
                        {renderFunctions()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Body;
