// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <p className="note">This site is a work in progress. Currently, we only accept MetaMask and you need to make sure that only one wallet is active in the browser.</p>
        </div>
    );
};

export default Footer;
