// src/App.js
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { fromBech32 } from '@harmony-js/crypto';
import contractABI from './abis/contractABI';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body';

const App = () => {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [contractAddress, setContractAddress] = useState('');
    const [error, setError] = useState('');
    const [functionInputs, setFunctionInputs] = useState({});
    const [contractInfo, setContractInfo] = useState({});
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const initWeb3 = async () => {
            const provider = await detectEthereumProvider();
            if (provider) {
                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);
            } else {
                setError('Please install MetaMask!');
            }
        };

        initWeb3();
    }, []);

    const connectMetaMask = async () => {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts[0]);
            setError('');
        } catch (err) {
            setError('Please connect to MetaMask.');
        }
    };

    const loadContract = async () => {
        try {
            const ethContractAddress = fromBech32(contractAddress);
            const contractInstance = new web3.eth.Contract(contractABI, ethContractAddress);
            setContract(contractInstance);
            setError('');
        } catch (err) {
            setError('Failed to load contract. Please check the contract address.');
        }
    };

    const handleFunctionCall = async (funcName) => {
        try {
            const inputs = functionInputs[funcName] || [];
            const result = await contract.methods[funcName](...inputs).call();
            setContractInfo(prevState => ({
                ...prevState,
                [funcName]: result
            }));
        } catch (err) {
            setError(`Failed to call ${funcName}. Please check the inputs.`);
        }
    };

    const handleFunctionSend = async (funcName) => {
        try {
            const inputs = functionInputs[funcName] || [];
            const tx = await contract.methods[funcName](...inputs).send({ from: account });
            setContractInfo(prevState => ({
                ...prevState,
                [funcName]: tx
            }));
        } catch (err) {
            setError(`Failed to send ${funcName}. Please check the inputs.`);
        }
    };

    const handleInputChange = (funcName, index, value) => {
        setFunctionInputs(prevState => {
            const newInputs = { ...prevState };
            if (!newInputs[funcName]) {
                newInputs[funcName] = [];
            }
            newInputs[funcName][index] = value;
            return newInputs;
        });
    };

    const toggleTheme = () => {
        const newTheme = !isDarkMode;
        setIsDarkMode(newTheme);
        document.body.className = newTheme ? 'dark' : 'light';
    };

    useEffect(() => {
        document.body.className = isDarkMode ? 'dark' : 'light';
    }, [isDarkMode]);

    return (
        <div className="app-container">
            <Header isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
            <Body
                account={account}
                connectMetaMask={connectMetaMask}
                contractAddress={contractAddress}
                setContractAddress={setContractAddress}
                loadContract={loadContract}
                contract={contract}
                error={error}
                functionInputs={functionInputs}
                handleFunctionCall={handleFunctionCall}
                handleFunctionSend={handleFunctionSend}
                handleInputChange={handleInputChange}
                contractInfo={contractInfo}
            />
            <Footer />
        </div>
    );
};

export default App;
